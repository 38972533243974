import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import interactionPlugin from '@fullcalendar/interaction';


let current = location.pathname;
if (current == '/calander') {

    document.addEventListener('DOMContentLoaded', function () {
        var calendarEl = document.getElementById('calendar');
        var calendar = new Calendar(calendarEl, {

            // plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin ],
            plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin,momentTimezonePlugin],
            // themeSystem: 'bootstrap',
            // timeZone: 'Europe/R',
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            //initialDate: '2021-01-12',
            navLinks: true, // can click day/week names to navigate views
            selectable: true,
            editable: true,
            dayMaxEvents: true, // allow "more" link when too many events
            // validRange: {
            //     start: '2017-05-01'
            //   }
            events: $('#calendarevents').val() == '' ? [] : JSON.parse($('#calendarevents').val()),

            eventContent: function (arg) {
                console.log(moment(arg.event.start).format('HH:mm a'));
                return {html:'<div data-toggle="tooltip" title="'+moment(arg.event.start).format('HH:mm')+ '- '+moment(arg.event.end).format('HH:mm')+' Happy Hour"><b>'+moment(arg.event.start).format('HH:mm')+ '- '+moment(arg.event.end).format('HH:mm') +' Happy Hour</b></div>'};
                },
            
            select: function (event) {
                // console.log($('#calendarevents').val());
                // console.log(moment(event.start).format("YYYY-MM-DD"));
                // console.log(moment(event.end, "MM-DD-YYYY hh:mm:ss a").format());
                // console.log(moment(event.start).add(2, 'days').format('YYYY-MM-DD HH:mm:ss'));
                var check = moment(event.start).format("YYYY-MM-DD");
                var today = moment(new Date()).format("YYYY-MM-DD");
                if (check < today) {
                    // Previous Day. show message if you want otherwise do nothing.
                    Swal.fire({
                        position: "top-end",
                        icon: "info",
                        title: 'Please choose current or future date',
                        showConfirmButton: false,
                        timer: 1500
                    });
                } else {
                    // Its a right date
                    axios.get("calander/create")
                        .then(res => {
                            $('.modal-form-sm').attr('id', res.data.formid);
                            $('#modal-title-sm').html(res.data.title);
                            $('#modal-body-sm').html(res.data.body);
                            $('.btn-sbm-sm').attr("id", "btm-cal-add");
                            $('.btn-sbm-sm').html(res.data.btntxt);
                            $("#start").datetimepicker({
                                autoclose: true,
                                todayBtn: true,
                                timezone: 'GMT+1',
                                startDate: res.data.date,
                            });
                            $('#start').val(moment(event.start).format('YYYY-MM-DD HH:mm'));
                            console.log(event);
                            console.log(res.data);
                            $('#modal-sm').modal("show");
                        })
                        .catch(err => {
                            console.log(err);
                        });


                    $(document).on('click', '#btm-cal-add', function (e) {
                        $(".alert-msg").html('');
                        let bnn_text = $(".btn-sbm-sm").html();
                        $(".btn-sbm-sm").html('<i class="fa fa-spinner"></i>');
                        let data = new FormData();
                        data.append('id', Math.random());
                        data.append('destination_id', $("#destination_id").val());
                        data.append('title', "Happy Hour");
                        data.append('start', moment($("#start").val()).format("YYYY-MM-DD HH:mm:ss"));
                        data.append('end', moment($("#end").val()).format("YYYY-MM-DD HH:mm:ss"));
                        axios.post("calander", data)
                            .then(res => {
                                Swal.fire({
                                    position: "top-end",
                                    icon: "success",
                                    title: res.data.msg,
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                                calendar.addEvent(res.data.event);
                                $(".btn-sbm-sm").html(bnn_text);
                                $('#modal-sm').modal("hide");
                            })
                            .catch(err => {
                                $(".btn-sbm-sm").html(bnn_text);
                                if (typeof err.response.data.msg != 'undefined') {
                                    $(".alert-msg").html(
                                        '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                                        err.response.data.msg +
                                        "</div>"
                                    );
                                }
                                else {
                                    let errors = "<ul>";
                                    $.each(err.response.data.errors, function (key, value) {
                                        errors += "<li>" + value + "</li>";
                                    });
                                    errors += "</ul>";
                                    $(".alert-msg").html(
                                        '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                                        errors +
                                        "</div>"
                                    );
                                }
                            });
                    });
                }

                //var eventData = { title: 'HAPPY HOUR', start: moment(event.start).format("YYYY-MM-DD HH:mm:ss"), end: '2020-12-30 10:00:00' };

                //calendar.addEvent(eventData);


                // console.log(event.start, event.end);
                // Display the modal.
                // You could fill in the start and end fields based on the parameters
                // $("#modal-sm").modal("show");
            },
            eventResize: function (info) {

                let data = new FormData();
                data.append("_method", "PUT");
                data.append('id', info.event.id);
                data.append('title', info.event.title);
                data.append('destination_id', info.event.extendedProps.destination_id);
                data.append('start', moment(info.event.start).format("YYYY-MM-DD HH:mm"));
                data.append('end', moment(info.event.end).format("YYYY-MM-DD HH:mm"));
                data.append('type', "resize");
                axios.post("calander/" + info.event.id, data)
                    .then(res => {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    })
                    .catch(err => {
                        info.revert();
                        if (typeof err.response.data.msg != 'undefined') {
                            Swal.fire({
                                position: "top-end",
                                icon: "delete",
                                title: err.response.data.msg,
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                        else {
                            let errors = "<ul>";
                            $.each(err.response.data.errors, function (key, value) {
                                errors += "<li>" + value + "</li>";
                            });
                            errors += "</ul>";
                            Swal.fire({
                                position: "top-end",
                                icon: "delete",
                                body: errors,
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                    });
            },

            eventDrop: function (info) {
                let data = new FormData();
                data.append("_method", "PUT");
                data.append('id', info.event.id);
                data.append('title', info.event.title);
                data.append('destination_id', info.event.extendedProps.destination_id);
                data.append('start', moment(info.event.start).format("YYYY-MM-DD HH:mm"));
                data.append('end', moment(info.event.end).format("YYYY-MM-DD HH:mm"));
                data.append('type', "drag");
                axios.post("calander/" + info.event.id, data)
                    .then(res => {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    })
                    .catch(err => {
                        info.revert();
                        if (typeof err.response.data.msg != 'undefined') {
                            Swal.fire({
                                position: "top-end",
                                icon: "delete",
                                title: err.response.data.msg,
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                        else {
                            let errors = "<ul>";
                            $.each(err.response.data.errors, function (key, value) {
                                errors += "<li>" + value + "</li>";
                            });
                            errors += "</ul>";
                            Swal.fire({
                                position: "top-end",
                                icon: "delete",
                                body: errors,
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                    });
            },

            eventClick: function (info) {
                // console.log(info.event.id);
                // console.log(info.event.extendedProps.destination.id);

                let view = '<div class="row">';

                view += '<div class="col-md-4">';
                view += 'Destination:';
                view += '</div>';
                view += '<div class="col-md-8">';
                view += info.event.extendedProps.destination.name;
                view += '</div>';

                view += '<div class="col-md-4">';
                view += 'Title :';
                view += '</div>';
                view += '<div class="col-md-8">';
                view += info.event.title;
                view += '</div>';

                view += '<div class="col-md-4">';
                view += 'Start :';
                view += '</div>';
                view += '<div class="col-md-8">';
                view += moment(info.event.start).format("DD-MM-YYYY hh:mm a");
                view += '</div>';

                view += '<div class="col-md-4">';
                view += 'End :';
                view += '</div>';
                view += '<div class="col-md-8">';
                view += moment(info.event.end).format("DD-MM-YYYY hh:mm a");
                view += '</div>';

                view += '</div>';
                $("#modal-body-sm").html(view);

                $('.btn-sbm-sm').attr("id", "btm-cal-del");
                $('.btn-sbm-sm').attr("data-id", info.event.id);
                $(".btn-sbm-sm").html('Delete');
                $("#modal-sm").modal("show");

                // $(document).on('click', '#btm-cal-del', function () {

                //     axios.delete("calander/" + $(this).attr("data-id"))
                //     .then(res => {
                //         info.event.remove();
                //         $("#modal-sm").modal("hide");
                //         Swal.fire({
                //             position: "top-end",
                //             icon: "success",
                //             title: res.data.msg,
                //             showConfirmButton: false,
                //             timer: 1500
                //         });
                //     })
                //     .catch(err => {
                //         console.log(err);
                //     });
                // });
            }
        });

        $(document).on('click', '#btm-cal-del', function () {

            axios.delete("calander/" + $(this).attr("data-id"))
            .then(res => {
                $(this).remove();
                $("#modal-sm").modal("hide");
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });

                window.setTimeout(function () {
                    window.location.reload();
                }, 1000);

            })
            .catch(err => {
                console.log(err);
            });
        });

        calendar.render();
    });

    $(document).on('click', '#end', function () {
        if ($("#start").val() == '') {
            $("#end").datetimepicker("remove");
            Swal.fire({
                position: "top-end",
                icon: "info",
                title: "Select Start Date first",
                showConfirmButton: false,
                timer: 1500
            });
        }
        else {
            $("#end").datetimepicker({
                autoclose: true,
                todayBtn: true,
                timezone: 'GMT+1',
                startDate: moment($("#start").val()).add(5, 'minutes').format('YYYY-MM-DD HH:mm'),
            });
            $("#end").datetimepicker("show");
        }
    });
    $(document).on('change', '#start', function () {
        $("#end").val('');
        $("#end").datetimepicker("remove");
    });
}
